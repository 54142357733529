export default [
  {
    text: "Член Лиги",
    link: { name: "MembershipPerson" },
    disabled: false,
  },
  {
    text: "Комитет Лиги",
    link: { name: "MembershipCommittee" },
    disabled: true,
  },
];
