<template>
  <div class="membership">
    <Title label="Членство" />
    <div class="membership__header">
      <TopSidebar :tabData="membershipBar" />
    </div>
    <router-view />
  </div>
</template>

<script>
import Title from "@/components/Blocks/Title";
import TopSidebar from "@/components/Blocks/TopSidebar";
import membershipBar from "@/enums/membershipBar";
export default {
  name: "Membership",
  components: { Title, TopSidebar },
  data() {
    return {
      membershipBar,
    };
  },
};
</script>

<style lang="scss"></style>
